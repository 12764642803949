<template>
    <div class="page-apply-shop">
        <div class="container">
            <div class="wrap" v-if="storehouseId">
                <van-field
                    label="共享仓名称"
                    label-class="field-label"
                    input-class="field-input"
                    input-align="right"
                    :value="storehouse ? storehouse.storehouseName : ''"
                    readonly
                />
            </div>
            <div class="wrap user-wrap">
                <van-cell-group :border="false">
                    <van-field
                        label="真实姓名"
                        label-class="field-label"
                        input-class="field-input"
                        input-align="right"
                        v-model="realName"
                        :readonly="applyStatus == 1 || applyStatus == 2 || applyStatus == 4"
                        placeholder="请输入真实姓名"
                    />
                    <van-field
                        label="联系电话"
                        label-class="field-label"
                        input-class="field-input"
                        input-align="right"
                        :readonly="$route.query.token ? true : false"
                        v-model="phone"
                        placeholder="请输入手机号码"
                    />
                    <van-cell title-class="field-label" title="店铺门头照" />
                    <div class="shop-image-wrap">
                        <div class="upload">
                            <van-uploader
                                :after-read="uploadShopImage"
                                :disabled="applyStatus == 1 || applyStatus == 2 || applyStatus == 4"
                            >
                                <img class="shop-image" src="@/assets/images/shop-image.png" v-if="!shopFrontImage" />
                                <img class="shop-image" :src="shopFrontImage | imagePrefix" v-else />
                                <van-button class="upload-action">
                                    请拍摄店铺门头照
                                </van-button>
                            </van-uploader>
                        </div>
                    </div>
                    <van-field
                        label="身份证号码"
                        label-class="field-label"
                        input-class="field-input"
                        input-align="right"
                        v-model="idCardNo"
                        :readonly="applyStatus == 1 || applyStatus == 2 || applyStatus == 4"
                        placeholder="请输入身份证号"
                        v-if="false"
                    />
                    <template v-if="false">
                        <van-cell title-class="field-label" title="身份证照片" />
                        <div class="id-card-wrap">
                            <div class="upload">
                                <van-uploader
                                    :after-read="uploadCardFrontImage"
                                    :disabled="applyStatus == 1 || applyStatus == 2 || applyStatus == 4"
                                >
                                    <img class="id-card" src="@/assets/images/id-card-front.png" v-if="!cardFrontImage" />
                                    <img class="id-card" :src="cardFrontImage | imagePrefix" v-else />
                                    <van-button class="upload-action">
                                        拍摄身份证正面
                                    </van-button>
                                </van-uploader>
                            </div>
                            <div class="upload">
                                <van-uploader
                                    :after-read="uploadCardBackImage"
                                    :disabled="applyStatus == 1 || applyStatus == 2 || applyStatus == 4"
                                >
                                    <img class="id-card" src="@/assets/images/id-card-back.png" v-if="!cardBackImage" />
                                    <img class="id-card" :src="cardBackImage | imagePrefix" v-else />
                                    <van-button class="upload-action">
                                        拍摄身份证反面
                                    </van-button>
                                </van-uploader>
                            </div>
                        </div>
                    </template>
                </van-cell-group>
            </div>
            <div class="wrap shop-wrap">
                <van-cell-group :border="false">
                    <van-field
                        label="店铺名称"
                        label-class="field-label"
                        input-class="field-input"
                        input-align="right"
                        v-model="shopName"
                        maxlength="16"
                        :readonly="applyStatus == 1 || applyStatus == 2 || applyStatus == 4"
                        placeholder="请输入店铺名称"
                    />
                    <van-field
                        label="店铺地址"
                        label-class="field-label"
                        input-class="field-input"
                        input-align="right"
                        v-model="shopAddress"
                        placeholder="请选择地址"
                        :readonly="true"
                        @click="show = true"
                    >
                        <img class="icon" src="@/assets/images/icon-advance.png" slot="right-icon" />
                    </van-field>
                    <van-field
                        label="详细地址"
                        label-class="field-label"
                        input-class="field-input"
                        input-align="right"
                        v-model="shopDetailsAddress"
                        :readonly="applyStatus == 1 || applyStatus == 2 || applyStatus == 4"
                        placeholder="请输入详细地址"
                    />
                    <van-cell title-class="field-label" title="店铺营业执照" />
                    <div class="license-wrap">
                        <div class="upload">
                            <van-uploader :after-read="uploadLicense" :disabled="applyStatus == 1 || applyStatus == 2 || applyStatus == 4">
                                <img class="license" src="@/assets/images/license.png" v-if="!shopLicenseImage" />
                                <img class="license" :src="shopLicenseImage | imagePrefix" v-else />
                                <van-button class="upload-action">
                                    请点击上传营业执照
                                </van-button>
                            </van-uploader>
                        </div>
                    </div>
                </van-cell-group>
            </div>
            <div class="wrap remark-wrap" v-if="applyStatus == 3">
                <div class="label">驳回原因：</div>
                <div class="value">
                    {{ applyDesc }}
                </div>
            </div>
            <div class="wrap agreement-wrap">
                <van-checkbox
                    v-model="checked"
                    icon-size=".42rem"
                    checked-color="#F43011"
                    :disabled="applyStatus == 1 || applyStatus == 2 || applyStatus == 4"
                >
                    <div class="agreement">
                        申请商家即视为同意
                        <div class="link" @click.stop="$router.push('/agreement?type=3')">
                            《厨为先商家协议》
                        </div>
                        ，
                        <div class="link" @click.stop="$router.push('/agreement?type=2')">
                            《厨为先隐私政策》
                        </div>
                    </div>
                </van-checkbox>
            </div>
            <div class="action-wrap">
                <van-button class="action" @click="submit" v-if="!applyStatus">
                    开始申请
                </van-button>
                <van-button class="action disabled" v-if="applyStatus == 1 || applyStatus == 2">
                    正在审核中,1~3个工作日
                </van-button>
                <van-button class="action" @click="submit" v-if="applyStatus == 3">
                    重新提交
                </van-button>
                <van-button class="action disabled" v-if="applyStatus == 4">
                    审核通过
                </van-button>
            </div>
        </div>
        <co-map v-if="show" :lat="form.latitude" :lng="form.longitude" @cancel="show = false" @confirm="onAddressChange" />
    </div>
</template>

<script>
import { CellGroup, Field, Cell, Button, Checkbox, Uploader, Toast } from 'vant';
import { upload } from '@/api/common';
import { appFindApplyShopInfo, appUser2ShopApply } from '@/api/shop';
import { appFindByStorehouseId } from '@/api/storehouse';
import { appMyInfo } from '@/api/my';

import CoMap from '@/components/Map';

export default {
    name: 'PageApplyShop',
    components: {
        [CellGroup.name]: CellGroup,
        [Field.name]: Field,
        [Cell.name]: Cell,
        [Button.name]: Button,
        [Checkbox.name]: Checkbox,
        [Uploader.name]: Uploader,
        CoMap
    },
    data() {
        return {
            id: 0,
            applyDesc: '',
            applyStatus: '', //1 申请中  2审核中 3驳回 4通过 5已注销
            realName: '',
            shopFrontImage: '',
            idCardNo: '',
            cardFrontImage: '',
            cardBackImage: '',
            shopName: '',
            shopAddress: '',
            shopLicenseImage: '',
            latitude: '',
            longitude: '',
            phone: '',
            shopDetailsAddress: '',
            storehouseId: '',
            storehouse: null,
            checked: true,
            show: false
        };
    },
    metaInfo: {
        title: '智能门店加盟'
    },
    computed: {
        form() {
            return {
                realName: this.realName,
                shopFrontImage: this.shopFrontImage,
                idCardNo: this.idCardNo,
                cardFrontImage: this.cardFrontImage,
                cardBackImage: this.cardBackImage,
                shopName: this.shopName,
                shopAddress: this.shopAddress,
                shopLicenseImage: this.shopLicenseImage,
                latitude: this.latitude,
                longitude: this.longitude,
                phone: this.phone,
                shopDetailsAddress: this.shopDetailsAddress,
                storehouseId: this.storehouseId
            };
        }
    },
    watch: {
        form() {
            localStorage.setItem('applyShopForm', JSON.stringify(this.form));
        }
    },
    created() {
        let form = localStorage.getItem('applyShopForm');
        form = form ? JSON.parse(form) : '';
        if (form && Object.keys(form).length) {
            //获取缓存数据
            let {
                realName,
                shopFrontImage,
                idCardNo,
                cardFrontImage,
                cardBackImage,
                shopName,
                shopAddress,
                shopLicenseImage,
                latitude,
                longitude,
                phone,
                shopDetailsAddress,
                storehouseId
            } = form;
            this['realName'] = realName;
            this['shopFrontImage'] = shopFrontImage;
            this['idCardNo'] = idCardNo;
            this['cardFrontImage'] = cardFrontImage;
            this['cardBackImage'] = cardBackImage;
            this['shopName'] = shopName;
            this['shopAddress'] = shopAddress;
            this['shopLicenseImage'] = shopLicenseImage;
            this['latitude'] = latitude;
            this['longitude'] = longitude;
            this['phone'] = phone;
            this['shopDetailsAddress'] = shopDetailsAddress;
            this['storehouseId'] = storehouseId;
        }
        if (this.$route.query.token) {
            let { token } = this.$route.query;
            this.$store.commit('SET_TOKEN', token);
            this.getDetail();
            this.getUserInfo();
        }
        if (this.$route.query.storehouseId) {
            this.storehouseId = this.$route.query.storehouseId;
            this.getStorehouseInfo();
        }
    },
    methods: {
        view(url) {
            wx.miniProgram.navigateTo({
                url
            });
        },
        getStorehouseInfo() {
            let storehouseId = this.$route.query.storehouseId;
            appFindByStorehouseId(storehouseId).then(res => {
                if (res && '999' === res.code) {
                    this.storehouse = res.data;
                }
            });
        },
        getUserInfo() {
            appMyInfo().then(res => {
                if (res && '999' === res.code) {
                    this.phone = res.data.phone;
                }
            });
        },
        getDetail() {
            Toast.loading({ duration: 0, message: '加载中' });
            appFindApplyShopInfo().then(res => {
                if (res && '999' === res.code) {
                    Toast.clear();
                    if (res.data) {
                        let {
                            id,
                            applyDesc,
                            applyStatus,
                            shopFrontImage,
                            cardBackImage,
                            cardFrontImage,
                            idCardNo,
                            latitude,
                            longitude,
                            realName,
                            shopAddress,
                            shopLicenseImage,
                            shopName,
                            shopDetailsAddress
                        } = res.data;
                        this.id = id;
                        this.applyDesc = applyDesc;
                        this.applyStatus = applyStatus;
                        this.shopFrontImage = shopFrontImage;
                        this.cardBackImage = cardBackImage;
                        this.cardFrontImage = cardFrontImage;
                        this.idCardNo = idCardNo;
                        this.latitude = latitude;
                        this.longitude = longitude;
                        this.realName = realName;
                        this.shopAddress = shopAddress;
                        this.shopLicenseImage = shopLicenseImage;
                        this.shopName = shopName;
                        this.shopDetailsAddress = shopDetailsAddress;
                    }
                }
            });
        },
        onAddressChange(location) {
            this.shopAddress = location.address;
            this.latitude = location.latLng.lat;
            this.longitude = location.latLng.lng;
            this.show = false;
        },
        //上传门脸图
        uploadShopImage(param) {
            const formData = new FormData();
            formData.append('files', param.file);
            upload(formData).then(res => {
                if (res && '999' === res.code) {
                    this.shopFrontImage = res.data.pics[0].filePath;
                }
            });
        },
        //上传身份证正面图片
        uploadCardFrontImage(param) {
            const formData = new FormData();
            formData.append('files', param.file);
            upload(formData).then(res => {
                if (res && '999' === res.code) {
                    this.cardFrontImage = res.data.pics[0].filePath;
                }
            });
        },
        //上传身份证反面图片
        uploadCardBackImage(param) {
            const formData = new FormData();
            formData.append('files', param.file);
            upload(formData).then(res => {
                if (res && '999' === res.code) {
                    this.cardBackImage = res.data.pics[0].filePath;
                }
            });
        },
        //上传营业许可
        uploadLicense(param) {
            const formData = new FormData();
            formData.append('files', param.file);
            upload(formData).then(res => {
                if (res && '999' === res.code) {
                    this.shopLicenseImage = res.data.pics[0].filePath;
                }
            });
        },
        //验证参数
        validate() {
            let {
                realName,
                shopFrontImage,
                // idCardNo,
                // cardFrontImage,
                // cardBackImage,
                shopName,
                shopAddress,
                shopLicenseImage,
                checked,
                shopDetailsAddress
            } = this;
            if (!realName.trim().length) {
                Toast.fail('请输入真实姓名');
                return false;
            }
            if (!/^1[0-9]{10}$/.test(this.phone)) {
                Toast.fail('手机号格式不正确');
                return false;
            }
            if (!shopFrontImage) {
                Toast.fail('请上传店铺门头照');
                return false;
            }
            // if (!/^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/.test(idCardNo)) {
            //     Toast.fail('身份证号码格式不正确');
            //     return false;
            // }
            // if (!cardFrontImage) {
            //     Toast.fail('请上传身份证正面照');
            //     return false;
            // }
            // if (!cardBackImage) {
            //     Toast.fail('请上传身份证反面照');
            //     return false;
            // }
            if (!shopName.trim().length) {
                Toast.fail('请输入店铺名称');
                return false;
            }
            if (!shopAddress.length) {
                Toast.fail('请选择店铺地址');
                return false;
            }
            if (!shopDetailsAddress.trim().length) {
                Toast.fail('请输入详细地址');
                return false;
            }
            if (!shopLicenseImage) {
                Toast.fail('请上传营业执照');
                return false;
            }
            if (!checked) {
                Toast.fail('请勾选协议');
                return false;
            }
            return true;
        },
        //提交申请
        submit() {
            if (!this.validate()) {
                return false;
            }
            let {
                id,
                realName,
                shopFrontImage,
                idCardNo,
                cardFrontImage,
                cardBackImage,
                shopName,
                latitude,
                longitude,
                shopAddress,
                shopLicenseImage,
                checked,
                storehouseId,
                shopDetailsAddress,
                phone
            } = this;
            Toast.loading({ duration: 0, message: '请求中' });
            appUser2ShopApply({
                id,
                realName,
                shopFrontImage,
                idCardNo,
                cardFrontImage,
                cardBackImage,
                shopName,
                latitude,
                longitude,
                shopAddress,
                shopLicenseImage,
                checked,
                storehouseId,
                shopDetailsAddress,
                phone
            }).then(res => {
                if (res && '999' === res.code) {
                    localStorage.removeItem('applyShopForm');
                    Toast.clear();
                    this.$router.push('/apply/success');
                }
            });
        }
    }
};
</script>

<style lang="less" scoped>
.page-apply-shop {
    background: #f6f6f6;
    padding-top: 24px;
    .container {
        width: 750px;
        margin: 0 auto;
        padding-bottom: 100px;
    }
    .wrap {
        background: #ffffff;
        box-shadow: 0px 6px 12px rgba(132, 132, 132, 0.16);
        border-radius: 20px;
        margin: 24px 14px 0 14px;
        padding: 10px 22px;
        overflow: hidden;
        &:first-child {
            margin-top: 0;
            padding: 0 22px;
        }
        & ::v-deep .field-label {
            font-size: 28px;
            line-height: 42px;
            font-weight: 500;
            color: #191919;
        }
        & ::v-deep .van-cell {
            padding: 22px 0;
        }
        & ::v-deep .van-cell:after {
            left: 0;
            right: 0;
        }
        & ::v-deep .van-field__value {
            font-size: 30px;
            line-height: 42px;
            color: #888;
        }
        & ::v-deep .van-uploader__wrapper--disabled {
            opacity: 1;
        }
    }
    .id-card-wrap {
        margin: 22px 0;
        display: flex;
        justify-content: space-between;
        .id-card {
            width: 330px;
            height: 208px;
            box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
            display: block;
            overflow: hidden;
        }
        .upload-action {
            border: none;
            display: block;
            width: 100%;
            background-color: #3186f0;
            font-size: 24px;
            font-weight: 500;
            line-height: 42px;
            color: #ffffff;
            height: 42px;
            border-radius: 0 0 8px 8px;
        }
    }
    .shop-image-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 22px 0;
        .shop-image {
            width: 330px;
            height: 208px;
            display: block;
            overflow: hidden;
        }
        .upload-action {
            display: block;
            width: 100%;
            background-color: #3186f0;
            font-size: 24px;
            font-weight: 500;
            line-height: 72px;
            color: #ffffff;
            height: 46px;
            border-radius: 0 0 20px 20px;
            border: none;
        }
    }
    .license-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 22px 0;
        .license {
            width: 322px;
            height: 412px;
            box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
            display: block;
            overflow: hidden;
        }
        .upload-action {
            display: block;

            width: 100%;
            background-color: #3186f0;
            font-size: 24px;
            font-weight: 500;
            line-height: 72px;
            color: #ffffff;
            height: 72px;
            border-radius: 0 0 20px 20px;
            border: none;
        }
    }
    .shop-wrap {
        .icon {
            width: 24px;
            height: 24px;
        }
    }
    .agreement-wrap {
        .agreement {
            font-size: 28px;
            font-weight: 500;
            line-height: 40px;
            color: #191919;
        }
        .link {
            display: inline;
        }
        .link:first-child {
            color: #e13d1d;
        }
        .link:last-child {
            color: #253479;
        }
    }
    .remark-wrap {
        background-color: #fff3f1;
        padding: 32px 22px;
        .label {
            font-size: 28px;
            font-weight: bold;
            line-height: 40px;
            color: #e13d1d;
            margin-bottom: 20px;
        }
        .value {
            font-size: 28px;
            font-weight: 500;
            line-height: 40px;
            color: #e13d1d;
        }
    }
    .action-wrap {
        margin-top: 124px;
        text-align: center;
        & ::v-deep .action {
            width: 526px;
            height: 80px;
            background: #e13d1d;
            border-radius: 36px;
            font-size: 28px;
            line-height: 80px;
            color: #ffffff;
        }
        & ::v-deep .disabled {
            background-color: #e1861d;
        }
    }
}
</style>
