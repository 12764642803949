import httpRequest from './httpRequest';

/**
 * app根据共享仓库ID获取仓库信息
 * @param { Number } Number shId
 * **/
export const appFindByStorehouseId = shId => {
    return httpRequest({
        url: '/storehouse/appFindByStorehouseId',
        method: 'post',
        data: {
            shId
        }
    });
};
