import httpRequest from './httpRequest';

/**
 * 获取申请记录
 * **/
export const appFindApplyShopInfo = () => {
    return httpRequest({
        url: '/shop/appFindApplyShopInfo',
        method: 'post'
    });
};

/**
 * 用户申请店铺
 * @param { String } applyDesc 申请备注
 * @param { String } cardBackImage 身份证反面
 * @param { String } cardFrontImage 身份证正面
 * @param { String } idCardNo 身份证号码
 * @param { String } latitude 纬度
 * @param { String } longitude 经度
 * @param { String } realName 真实名字
 * @param { String } shopAddress 	店铺地址
 * @param { String } shopLicenseImage 店铺营业执照
 * @param { String } shopName 店铺名称
 * @param { Number } userId 店长用户id(tb_user_id)
 * @param { Number } storehouseId 仓库
 * @param { String } phone 手机号
 * @param { String } shopFrontImage 店铺门脸图
 *
 * **/
export const appUser2ShopApply = ({
    id,
    applyDesc,
    cardBackImage,
    cardFrontImage,
    idCardNo,
    latitude,
    longitude,
    realName,
    shopAddress,
    shopLicenseImage,
    shopName,
    userId,
    storehouseId,
    phone,
    shopDetailsAddress,
    shopFrontImage
}) => {
    return httpRequest({
        url: '/shop/v3_appUser2ShopApply',
        method: 'post',
        data: {
            id,
            applyDesc,
            cardBackImage,
            cardFrontImage,
            idCardNo,
            latitude,
            longitude,
            realName,
            shopAddress,
            shopLicenseImage,
            shopName,
            userId,
            storehouseId,
            phone,
            shopDetailsAddress,
            shopFrontImage
        }
    });
};
