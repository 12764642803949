import httpRequest from "./httpRequest";

export const upload = files => {
    return httpRequest({
        url: "/common/v3_10_uploadForm",
        method: "post",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: files
    });
};
