<template>
    <van-overlay :show="true">
        <div class="co-map" @touchmove.prevent>
            <div class="map-container" id="mapContainer">
                <div class="action-wrap">
                    <div class="action cancel" @click="cancel">取消</div>
                    <div class="action confirm" @click="confirm">确定</div>
                </div>
                <img src="@/assets/images/position.png" alt="" class="icon" />
            </div>
            <div class="result-wrap" @touchmove.stop>
                <div class="search-wrap">
                    <input type="text" v-model="keyword" placeholder="搜索地址" class="search" @input="onSearch" />
                </div>
                <div class="list" v-if="!searchResult.length">
                    <div
                        :class="['item', item.id === active.id ? 'active' : '']"
                        v-for="item in near"
                        :key="item.id"
                        @click="onActiveChange(item)"
                    >
                        <div class="name">{{ item.name }}</div>
                        <div class="address">{{ item.address }}</div>
                    </div>
                </div>
                <div class="list" v-else>
                    <div
                        :class="['item', item.id === active.id ? 'active' : '']"
                        v-for="item in searchResult"
                        :key="item.id"
                        @click="onActiveChange(item, 'search')"
                    >
                        <div class="name">{{ item.title }}</div>
                        <div class="address">{{ item.address }}</div>
                    </div>
                </div>
            </div>
        </div>
    </van-overlay>
</template>

<script>
// import maps from 'qqmap';
const key = 'AKHBZ-DJOLS-RYLOV-6Y6P5-FASGE-K5FYP';
import { Overlay } from 'vant';
import { jsonp } from 'vue-jsonp';
import { Toast } from 'vant';

let timer = null;
let handleDragTimer = null;
let getAddress;

export default {
    name: 'CoMap',
    components: { [Overlay.name]: Overlay },
    props: {
        lat: {
            type: String | Number
        },
        lng: {
            type: String | Number
        }
    },
    data() {
        return {
            near: [],
            searchResult: [],
            active: null,
            keyword: ''
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        getLocation() {
            let geolocation = new qq.maps.Geolocation(key, 'cookerFirstH5');
            geolocation.getIpLocation(
                ipPosition => {
                    //获取ip定位
                    let { lat, lng } = ipPosition;
                    this.setCenterPoint(lat, lng);
                    setTimeout(() => {
                        getAddress.getAddress(this.mapInstance.center);
                        //尝试获取高精度定位
                        geolocation.getLocation(
                            position => {
                                let { lat, lng } = position;
                                this.setCenterPoint(lat, lng);
                                setTimeout(() => {
                                    getAddress.getAddress(this.mapInstance.center);
                                }, 300);
                            },
                            {
                                timeout: 6000
                            }
                        );
                    }, 300);
                },
                error => {
                    Toast.fail('获取定位失败');
                }
            );
        },
        setCenterPoint(lat, lng) {
            this.mapInstance.panTo(new qq.maps.LatLng(lat, lng));
        },
        init() {
            let lat = 39.916527,
                lng = 116.397128;
            if (this.$props.lat && this.$props.lng) {
                (lat = this.$props.lat), (lng = this.$props.lng);
            }
            // maps.init(key, () => {
            const latlng = new qq.maps.LatLng(lat, lng);
            const options = {
                zoom: 15,
                center: latlng,
                mapTypeId: qq.maps.MapTypeId.ROADMAP,
                zoomControl: false,
                mapTypeControl: false
            };
            this.mapInstance = new qq.maps.Map(document.getElementById('mapContainer'), options);
            getAddress = new qq.maps.Geocoder({
                complete: res => {
                    this.near = res.detail.nearPois;
                    this.active = this.near[0];
                    console.log(this.near);
                    this.near.forEach(item => {
                        if (item.latLng.lat === this.$props.lat && item.latLng.lng === this.$props.lng) {
                            this.active = item;
                        }
                    });
                    if (!this.actve) {
                        this.near[0];
                    }
                }
            });
            qq.maps.event.addListener(this.mapInstance, 'drag', () => {
                clearTimeout(handleDragTimer);
                handleDragTimer = setTimeout(() => {
                    this.searchResult = [];
                    this.keyword = '';
                    getAddress.getAddress(this.mapInstance.center);
                }, 300);
            });

            if (!(this.$props.lat && this.$props.lng)) {
                this.getLocation();
            }
            getAddress.getAddress(this.mapInstance.center);
        },

        onSearch() {
            clearTimeout(timer);
            timer = setTimeout(() => {
                const keyword = this.keyword;
                jsonp('https://apis.map.qq.com/ws/place/v1/suggestion', {
                    keyword,
                    key,
                    output: 'jsonp'
                }).then(res => {
                    if (res && 0 === res.status) {
                        this.searchResult = res.data;
                    }
                });
            }, 300);
        },
        onActiveChange(location, type) {
            if (type === 'search') {
                location.latLng = location.location;
                let { lat, lng } = location.latLng;
                this.setCenterPoint(lat, lng);
            } else {
                let { lat, lng } = location.latLng;
                this.setCenterPoint(lat, lng);
            }

            this.active = location;
        },
        cancel() {
            this.$emit('cancel');
        },
        confirm() {
            this.$emit('confirm', this.active);
        }
    }
};
</script>

<style lang="less">
.co-map {
    width: 750px;
    height: 100%;
    margin: 0 auto;
    .map-container {
        width: 100%;
        height: calc(100vh - 580px);
        position: relative;
        .action-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 750px;
            padding: 32px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(128, 128, 128, 0) 100%);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 99;
        }
        .action {
            font-size: 30px;
            line-height: 58px;
            padding: 0 20px;
            height: 58px;
            color: #fff;
        }
        .confirm {
            width: 104px;
            background: #04c160;
            border-radius: 10px;
        }
        .icon {
            width: 116px;
            height: 116px;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 99;
            transform: translate(-50%, -50%);
        }
    }
    .result-wrap {
        height: 580px;
        background-color: #fff;
        position: relative;
        padding-top: 128px;
    }
    .search-wrap {
        position: absolute;
        top: 28px;
        left: 28px;
        width: 694px;
        height: 70px;
        background: #f4f4f4;
        border-radius: 10px;
        margin: 0 auto;
        display: flex;
        .search {
            display: block;
            width: 100%;
            background: transparent;
            border: none;
            font-size: 30px;
            font-weight: 500;
            line-height: 42px;
            color: #191919;
            text-align: center;
            &:focus {
                outline: none;
            }
            &::-webkit-input-placeholder {
                color: #888;
            }
            &::-moz-placeholder {
                color: #888;
            }
            &:-ms-input-placeholder {
                color: #888;
            }
            &:-moz-placeholder {
                color: #888;
            }
        }
    }
    .list {
        padding: 0 32px;
        height: 452px;
        overflow: scroll;
    }
    .item {
        padding: 32px 60px 32px 14px;
        border-bottom: 1px solid rgba(226, 226, 226, 0.2);
        &.active {
            background: url(~@/assets/images/address-active.png);
            background-repeat: no-repeat;
            background-size: 64px;
            background-position: right center;
        }
    }
    .name {
        font-size: 30px;
        font-weight: 500;
        line-height: 42px;
        color: #191919;
    }
    .address {
        font-size: 26px;
        line-height: 36px;
        color: #888;
    }
}
</style>
