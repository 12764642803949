import httpRequest from './httpRequest';

export const appMyInfo = () => {
    return httpRequest({
        url: '/my/v3_appMyInfo',
        method: 'post'
    });
};

export const appFindShInfo = ({ shId, type }) => {
    return httpRequest({
        url: '/my/appFindShInfo',
        method: 'post',
        data: {
            shId,
            type
        }
    });
};
